
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <b-tabs
            active-nav-item-class="nav nav-tabs"
            content-class="mt-3"
    >
      <b-tab title="All" active>

        <div class="row">
          <div class="col-md-3">
            <label><strong>Filter by status:</strong></label> <br/>
            <select class="form-control" v-model="orderStatus" @change="getSalesOrdersData">
              <option value="all">All</option>
                <option value="CONFIRMED">Confirmed</option>
                <option value="PRODUCTION">Production</option>
                <option value="READY">Ready</option>
                <option value="DELIVERED">Delivered</option>
            </select>
          </div>
           <div class="col-md-3">
             <label><strong>Filter by order date:</strong></label> <br/>
             <date-picker style="width: 100%;"   :lang="lang" v-model="orderDate" valueType="format"  @input="getSalesOrdersData" :first-day-of-week="1"></date-picker>
           </div>
          <div class="col-md-3">
            <label><strong>Filter by delivery date:</strong></label> <br/>
            <date-picker style="width: 100%;"   :lang="lang" v-model="deliveryDate" valueType="format"  @input="getSalesOrdersData" :first-day-of-week="1"></date-picker>
          </div>
        </div>

        <div class="col-md-3">

        </div>

        <b-table striped hover :items="sales_orders" :fields="columns">

          <template v-slot:cell(customer)="data">
            <span >{{ data.item.customer.clientName }}</span>
          </template>
          <template v-slot:cell(items)="data">
             <ul>
               <li style="border-bottom: 2px solid #ccc;" v-for="orderItem in data.item.items">
                 <span v-if="orderItem.product.productType == 'IRON_SHEET'">
                   <strong>Name:</strong> {{ orderItem.product.productName }} <br/>
                   <strong>Gauge:</strong> {{ orderItem.product.gauge }} <br/>
                   <strong>Qty:</strong> {{ orderItem.qty }} <br/>
                   <strong>Size (m):</strong> {{ orderItem.sizeInMetres }}
                 </span>
                 <span v-else>
                   <strong>Name:</strong> {{ orderItem.product.productName }} <br/>
                   <strong>Qty:</strong> {{ orderItem.qty }} <br/>
                 </span>
               </li>
             </ul>
          </template>

          <template v-slot:cell(status)="data">
            <span v-if="data.item.status == 'CONFIRMED' || data.item.status == 'PENDING'" class="badge badge-warning"> Confirmed </span>
            <span v-else-if="data.item.status == 'PRODUCTION'" class="badge badge-primary"> Production </span>
            <span v-else-if="data.item.status == 'READY'" class="badge badge-success"> Ready </span>
            <span v-else-if="data.item.status == 'DELIVERED'" class="badge badge-info"> Delivered </span>
          </template>


          <template v-slot:cell(options)="data">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <b-dropdown text="options">
              <b-dropdown-item href="#" @click="openSalesOrderUpdateStatus(data.item)">Update status</b-dropdown-item>
            </b-dropdown>

          </template>
        </b-table>

        <div class="row">
          <div class="col-md-12">

            <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
              <ul class="pagination justify-content-center">
                <li v-if="pagination.current_page > 1" class="page-item"><a @click.prevent="changePage(pagination.current_page - 1)" class="page-link" href="#">Previous</a></li>
                <li v-for="page in pagesNumber"
                    v-bind:class="[ page == isActived ? 'active' : '']" class="page-item"><a @click.prevent="changePage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                <li v-if="pagination.current_page < pagination.last_page"  class="page-item"><a  @click.prevent="changePage(pagination.current_page + 1)"class="page-link" href="#">Next</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </b-tab>
      <!--<b-tab title="Confirmed" >
        <div class="row">
          <div class="col-md-3">
            <b-input-group append="Find" class="mb-2 mr-sm-2 mb-sm-0">
              <b-input id="inline-form-input-username" placeholder="Search "></b-input>
            </b-input-group>
          </div>
          <div class="col-md-9">
            <b-button class="btn btn-danger float-right" v-b-modal.add-group>Add new</b-button>
          </div>
        </div>

          <b-table striped hover :items="groups" :fields="columns">

          <template v-slot:cell(groupAdmin)="data">
            <span>{{ data.value.name }} ({{ data.value.username}} )</span>
          </template>
          <template v-slot:cell(npsEntity)="data">
            <span>{{ data.value.name }}</span>
          </template>

          <template v-slot:cell(parent)="data">
            <span v-if="data.value.parent != ''">{{ data.value.name }}</span>
            <span v-else> None </span>
          </template>

          <template v-slot:cell(options)="data">
            &lt;!&ndash; `data.value` is the value after formatted by the Formatter &ndash;&gt;
            <b-dropdown text="options">
              <b-dropdown-item href="#" @click="openEditGroupModal(data.item)">Edit</b-dropdown-item>
              <b-dropdown-item href="#" @click="openDeleteGroup(data.item)">Delete</b-dropdown-item>
            </b-dropdown>

          </template>
        </b-table>
      </b-tab>
      <b-tab title="Production" >
        <div class="row">
          <div class="col-md-3">
            <b-input-group append="Find" class="mb-2 mr-sm-2 mb-sm-0">
              <b-input id="inline-form-input-username" placeholder="Search group"></b-input>
            </b-input-group>
          </div>
          <div class="col-md-9">
            <b-button class="btn btn-danger float-right" v-b-modal.add-group>Add new</b-button>
          </div>
        </div>

        <b-table striped hover :items="groups" :fields="columns">

          <template v-slot:cell(groupAdmin)="data">
            <span>{{ data.value.name }} ({{ data.value.username}} )</span>
          </template>
          <template v-slot:cell(npsEntity)="data">
            <span>{{ data.value.name }}</span>
          </template>

          <template v-slot:cell(parent)="data">
            <span v-if="data.value.parent != ''">{{ data.value.name }}</span>
            <span v-else> None </span>
          </template>

          <template v-slot:cell(options)="data">
            &lt;!&ndash; `data.value` is the value after formatted by the Formatter &ndash;&gt;
            <b-dropdown text="options">
              <b-dropdown-item href="#" @click="openEditGroupModal(data.item)">Edit</b-dropdown-item>
              <b-dropdown-item href="#" @click="openDeleteGroup(data.item)">Delete</b-dropdown-item>
            </b-dropdown>

          </template>
        </b-table>


      </b-tab>
      <b-tab title="Ready" >
        <div class="row">
          <div class="col-md-3">
            <b-input-group append="Find" class="mb-2 mr-sm-2 mb-sm-0">
              <b-input id="inline-form-input-username" placeholder="Search group"></b-input>
            </b-input-group>
          </div>
          <div class="col-md-9">
            <b-button class="btn btn-danger float-right" v-b-modal.add-group>Add new</b-button>
          </div>
        </div>

        <b-table striped hover :items="groups" :fields="columns">

          <template v-slot:cell(groupAdmin)="data">
            <span>{{ data.value.name }} ({{ data.value.username}} )</span>
          </template>
          <template v-slot:cell(npsEntity)="data">
            <span>{{ data.value.name }}</span>
          </template>

          <template v-slot:cell(parent)="data">
            <span v-if="data.value.parent != ''">{{ data.value.name }}</span>
            <span v-else> None </span>
          </template>

          <template v-slot:cell(options)="data">
            &lt;!&ndash; `data.value` is the value after formatted by the Formatter &ndash;&gt;
            <b-dropdown text="options">
              <b-dropdown-item href="#" @click="openEditGroupModal(data.item)">Edit</b-dropdown-item>
              <b-dropdown-item href="#" @click="openDeleteGroup(data.item)">Delete</b-dropdown-item>
            </b-dropdown>

          </template>
        </b-table>

      </b-tab>
      <b-tab title="Delivered" >
        <div class="row">
          <div class="col-md-3">
            <b-input-group append="Find" class="mb-2 mr-sm-2 mb-sm-0">
              <b-input id="inline-form-input-username" placeholder="Search group"></b-input>
            </b-input-group>
          </div>
          <div class="col-md-9">
            <b-button class="btn btn-danger float-right" v-b-modal.add-group>Add new</b-button>
          </div>
        </div>

        <b-table striped hover :items="groups" :fields="columns">

          <template v-slot:cell(groupAdmin)="data">
            <span>{{ data.value.name }} ({{ data.value.username}} )</span>
          </template>
          <template v-slot:cell(npsEntity)="data">
            <span>{{ data.value.name }}</span>
          </template>

          <template v-slot:cell(parent)="data">
            <span v-if="data.value.parent != ''">{{ data.value.name }}</span>
            <span v-else> None </span>
          </template>

          <template v-slot:cell(options)="data">
            &lt;!&ndash; `data.value` is the value after formatted by the Formatter &ndash;&gt;
            <b-dropdown text="options">
              <b-dropdown-item href="#" @click="openEditGroupModal(data.item)">Edit</b-dropdown-item>
              <b-dropdown-item href="#" @click="openDeleteGroup(data.item)">Delete</b-dropdown-item>
            </b-dropdown>

          </template>
        </b-table>

      </b-tab>-->
    </b-tabs>

    <b-modal id="updateStatus" title="Update Order Status" @cancel="true"  @ok="submitSalesOrderUpdate"  centered>

      <div class="row mb-3">
        <div class="col-md-12">
          <label><strong>Delivery Date: </strong></label> <br/>
          <date-picker style="width: 100%;"   :lang="lang" v-model="salesOrderStatusUpdate.deliveryDate" valueType="format" :first-day-of-week="1"></date-picker>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <label><strong>Order Status: </strong></label> <br/>
          <label>
              <input type="radio" name="orderStatus" value="CONFIRMED" v-model="salesOrderStatusUpdate.status" /> &nbsp; Confirmed
          </label> <br/>
          <label>
            <input type="radio" name="orderStatus" value="PRODUCTION" v-model="salesOrderStatusUpdate.status" /> &nbsp; In production
          </label> <br/>
          <label>
            <input type="radio" name="orderStatus" value="READY" v-model="salesOrderStatusUpdate.status" /> &nbsp; Ready
          </label> <br/>
          <label>
            <input type="radio" name="orderStatus" value="DELIVERED" v-model="salesOrderStatusUpdate.status" /> &nbsp; Dispatched
          </label>
        </div>
      </div>

    </b-modal>




  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';


export default {
  data() {
    return {
      disabled:false,
      isLoading: false,
      fullPage: true,
      columns:["invoiceId","customer","destination","orderDate","deliveryDate","status","items","orderPayments", "options"],
      sales_orders:[],
      orderStatus:"all",
      orderDate:"all",
      deliveryDate:"all",
      offset: 4,
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      salesOrderStatusUpdate:{
         id:0,
         status:"",
         deliveryDate:""
      },
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      },
    };
  },
  components: {
    Loading,
    DatePicker
  },
  computed: {
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }



      return pagesArray;
    }

  },
  mounted (){
    this.getSalesOrdersData();
  },
  methods: {
    ...mapActions(["getSalesOrders","updateSalesOrder"]),
    openSalesOrderUpdateStatus(salesOrderStatusUpdate){
      this.salesOrderStatusUpdate.id = salesOrderStatusUpdate.id;
      this.salesOrderStatusUpdate.status = salesOrderStatusUpdate.status;

      if(salesOrderStatusUpdate.deliveryDate){
        this.salesOrderStatusUpdate.deliveryDate = salesOrderStatusUpdate.deliveryDate;
      }


      this.$bvModal.show('updateStatus');
    },

    getSalesOrdersData(){
      let self = this;
      this.getSalesOrders({page:this.pagination.current_page,orderStatus:this.orderStatus,orderDate:this.orderDate,deliveryDate:this.deliveryDate})
        .then(function (sales_orders) {
          self.sales_orders = sales_orders.content;

          if(sales_orders.content){
            self.pagination.current_page = sales_orders.number;
            self.pagination.total = sales_orders.totalElements;
            self.pagination.per_page = sales_orders.numberOfElements;
            self.pagination.from = sales_orders.pageable.offset + 1 ;
            self.pagination.to = sales_orders.pageable.pageSize;
            self.pagination.last_page = sales_orders.totalPages ;
          }
        })
        .catch(function (error) {


          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    submitSalesOrderUpdate(){
      let self = this;

      this.isLoading = true;



      this.updateSalesOrder(this.salesOrderStatusUpdate)
              .then(function () {
                self.isLoading = false;

                self.$bvToast.toast("Order updated", {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                self.getSalesOrdersData();

              })
              .catch(function (error) {
                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });

              })
    },
  }
};
</script>
